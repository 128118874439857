import { FaqFilter, FaqSection } from '@app/constants/ApiTypes/requests'

import * as api from '@app/utils/api'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'

import { getFaqBySectionDescriptor, getFaqDescriptor } from './faq.descriptors'

export const getFaq = new ApiActionBuilder(getFaqDescriptor)
  .setInit((filter?: FaqFilter) => ({
    method: 'GET',
    endpoint: api.path('/api/v2/faq/', filter),
    headers: api.headers(),
    meta: { filter },
  }))
  .build()

export const getFaqBySection = new ApiActionBuilder(getFaqBySectionDescriptor)
  .setInit((section: FaqSection) => ({
    method: 'GET',
    endpoint: api.path(`/api/v2/faq/${section}`),
    headers: api.headers(),
    meta: { section },
  }))
  .build()
