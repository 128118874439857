import { ApiFaq } from '@app/constants/ApiTypes/entities'
import { ApiDataResponse } from '@app/constants/ApiTypes/misc'
import { FaqFilter, FaqSection } from '@app/constants/ApiTypes/requests'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const getFaqDescriptor = new ApiActionBuilderDescriptor().setLabel('getFaq').setShape<ApiDataResponse<ApiFaq>, { filter?: FaqFilter }>()

export const getFaqBySectionDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('getFaqBySection')
  .setShape<ApiDataResponse<ApiFaq>, { section: FaqSection }>()
