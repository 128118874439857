import moment from '@app/utils/moment'

import { Task } from '@app/packages/task/Task'

import { createThunk } from '@app/store/thunk'

import { getFaq, getFaqBySection } from './api/faq'
import { createTask, withTaskContext } from './tasks'

export const getFaqTask = () =>
  createTask('Faq Task', dispatch =>
    Task.create(ctx => async () => {
      return await dispatch(withTaskContext(ctx, getFaq()))
    })
  )

export const getSectionFaqTask = (section: 'parents/landing' | 'sitters/landing' | 'playground/landing') =>
  createTask(`Faq task section ${section}`, dispatch =>
    Task.create(ctx => async () => {
      return await dispatch(withTaskContext(ctx, getFaqBySection(section)))
    })
  )

export const getSitterKnowledgeFaqTask = () =>
  createTask('Faq sitter knowledge', dispatch =>
    Task.create(ctx => async () => {
      return await dispatch(
        withTaskContext(
          ctx,
          getFaq({
            'filter[for_sitters]': 'true',
            'filter[knowledgebase]': 'true',
          })
        )
      )
    })
  )

export const getSitterKnowledgeFaq = () =>
  createThunk(async dispatch => {
    const task = dispatch(getSitterKnowledgeFaqTask())
    if (task.value.value && task.loadedAt.value && task.loadedAt.value.isAfter(moment().subtract(1, 'hour'))) {
      return task.value.value
    }
    return await task.call()
  })
